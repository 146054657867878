@import '@Hometree-styles/vars.scss';

.controlForm {
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    margin: 0 auto 1.5rem;

    .selectConfig {
        min-width: 230px;
    }

    .billingControls {
        align-content: center;
        align-items: center;
        display: flex;
        flex-direction: row;
        font-size: 0.75rem;
        gap: 0.5rem;
        height: fit-content;
        justify-content: center;

        @media (max-width: $breakpoint-mobile) {
            display: inline-block;
            font-size: 0.75rem;
            text-align: center;
        }

        h5 {
            font-size: 1.2rem;
            margin: 0;
        }

        > div {
            background-color: #fff;
            border: 1px solid rgba($primary-dark-green, 0.3);
            border-radius: 2rem;
            display: flex;
            height: 33px;
            justify-content: space-between;
            overflow: hidden;
            padding: 0.2rem;
            position: relative;

            input {
                left: -10px;
                position: absolute;
                top: -10px;
            }

            label {
                border-radius: 2rem;
                cursor: pointer;
                padding: 0.5rem 0.75rem;
            }

            input:checked + label {
                background-color: $primary-dark-green;
                color: $primary-yellow;
            }
        }
    }

    .contributionControls {
        align-content: center;
        align-items: center;
        display: flex;
        flex-direction: row;
        height: fit-content;
        justify-content: center;
        margin: 0;
        gap: 0.5rem;

        @media (max-width: $breakpoint-mobile) {
            display: inline-block;
            text-align: center;
        }

        h5 {
            font-size: 1.2rem;
            margin: 0;

            button {
                background-color: transparent;
                border: none;
                margin-left: 0.5rem;
                padding: 0;
                vertical-align: middle;
            }
        }

        .contributionPrice {
            pointer-events: none;
        }

        .contributionButton {
            cursor: pointer;
        }
    }
}

.popover {
    font-size: 1rem;
    position: relative;
    text-align: left;
    width: fit-content;

    .popoverTitle {
        text-decoration: underline;

        &:hover {
            font-weight: 500;
        }
    }

    .popoverContent {
        background-color: $primary-highlight-green;
        border-radius: 1.25rem;
        font-size: 0.875rem;
        left: 5rem;
        letter-spacing: 0;
        opacity: 0;
        padding: 1rem;
        position: absolute;
        text-align: left;
        top: 15px;
        visibility: hidden;
        width: 20rem;

        h3 {
            font-family: $font-body;
            font-size: 0.875rem;
            font-weight: 600;
            letter-spacing: 0;
            margin: 0 0 1rem;
        }
    }

    &:hover .popoverContent {
        opacity: 1;
        visibility: visible;
        z-index: 10;
    }
}

.plansTableContainer {
    display: block;
    text-align: center;

    .hoWidth {
        width: 20%;
    }

    .llWidth {
        width: 25%;
    }

    .plansTable {
        display: flex;
        justify-content: center;
        background-color: #fff;
        border: 1px solid rgba($primary-dark-green, 0.3);
        border-radius: 2rem;
        padding: 2rem;

        td,
        th {
            border: none;
            text-align: center;

            &.essentialsDisabled {
                color: #808080;

                svg path {
                    fill: #808080;
                }
            }
        }

        .essentialsPrice {
            vertical-align: bottom;
        }

        tr {
            height: 35px;

            > td {
                font-size: 0.75rem;
                vertical-align: middle;

                &.inclusionCell {
                    padding: 0rem 1rem;
                    vertical-align: top;
                    width: 200px;
                }
            }

            &.inclusions h6 {
                margin: 0;
                text-align: left;
            }
        }

        .tableHeader {
            border-top-left-radius: 1.25rem;
            border-top-right-radius: 1.25rem;
            margin: 0 0.75rem 0;
            min-width: 150px;
            padding: 1rem 0.75rem;
            position: relative;

            h5 {
                color: inherit;
                margin: 0;
            }

            &.essentialsDisabled {
                background-color: #eaeaea;
                color: #808080;
            }

            .discount {
                color: $secondary-purple-900;
                font-family: $font-heading;
                font-size: 1.25rem;
                font-weight: 700;
                height: 64px;
                height: 0;
                line-height: 85%;
                padding-top: 1rem;
                position: absolute;
                right: -15px;
                top: -32px;
                width: 64px;
                z-index: 10;

                &::before {
                    background-color: $secondary-purple-100;
                    border-radius: 100%;
                    content: '';
                    height: 64px;
                    left: 0;
                    position: absolute;
                    top: 0;
                    width: 64px;
                    z-index: -1;
                }
            }
        }

        .descriptionCell {
            text-align: left;
        }

        .pricesRow {
            h3 {
                margin: 0.25rem 0 0.25rem;
            }

            span {
                display: block;
                font-size: 0.75rem;
                margin-bottom: 1rem;
            }

            h4 {
                color: inherit;
                font-size: 1rem;
                margin: 1rem 0 0 0;

                > svg {
                    position: absolute;
                    transform: scale(0.75);
                    transform: translate(-25%, -2px); //transform: translate(-7px, -2px); ?

                    path {
                        stroke: $primary-dark-green;
                    }
                }
            }

            .spacer {
                height: 18px;
            }

            h4 {
                color: inherit;
                margin: 1rem 0 0 0;
                font-size: 1rem;

                > svg {
                    position: absolute;
                    transform: scale(0.75);
                    transform: translate(-25%, -2px); //transform: translate(-7px, -2px); ?

                    path {
                        stroke: $primary-dark-green;
                    }
                }
            }

            .spacer {
                height: 18px;
            }
        }

        .ctaRow {
            button {
                font-size: 0.8rem;
                font-weight: 500;
                margin: 0 auto 0.5rem;
            }
        }
    }

    .loadingOverlay {
        position: relative;
        border: 1px solid rgba($primary-dark-green, 0.3);
        display: block;
        width: 100%;
        height: 600px;
        cursor: not-allowed;
        background-color: transparentize($color: $primary-dark-green, $amount: 0.6);
        z-index: 1;
        border-radius: 2rem;

        > span {
            position: absolute;
            left: calc(50% - 2rem);
            top: calc(50% - 2rem);
            width: 4rem;
            height: 4rem;
            border: 9px solid $primary-dark-green;
            border-bottom-color: #fff;
            border-radius: 50%;
            display: inline-block;
            box-sizing: border-box;
            animation: rotation 1s linear infinite;
        }

        @keyframes rotation {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }

        p {
            position: absolute;
            left: calc(50%);
            top: calc(50% + 3rem);
            font-weight: 600;
            transform: translate(-50%, 0);
        }
    }
}

.TCLink {
    margin-top: 1rem;
}

.bullets {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    .inclusionsPoint {
        align-items: center;
        display: flex;
        gap: 0.25rem;
        text-align: start;

        svg {
            height: 0.8rem;
        }

        p {
            width: 80%;
            font-size: 0.9rem;
            line-height: 1;
            margin: 0;
        }
    }
}

.plansCarousel {
    display: none;
    width: 100%;

    @media (max-width: calc($breakpoint-mobile + 300px)) {
        display: block;
    }
}

.voucherRow {
    .voucher {
        background-color: $primary-highlight-green;
        border-radius: 6px;
        font-weight: 500;
        margin: auto;
        max-width: 6rem;
        padding: 0.6rem 0;
        width: 80%;
    }
}
