@import '@Hometree-styles/vars.scss';

.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $secondary-black;
    padding: 1rem 3rem;

    .headerText {
        color: $primary-off-white;
        font-weight: 600;
        font-style: normal;
        margin: 1.3rem;
    }
}
