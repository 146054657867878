.contactInformationForm {
    padding: 20px 30px 35px;

    form {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;

        .textInput {
            min-width: 300px;
            width: 47%;
            margin-bottom: 1rem;

            label {
                font-size: 1rem;
                font-weight: 500;
                margin-left: 24px;
            }
        }
    }

    .nextBtn {
        width: 206px;
        justify-content: center;
        margin-left: auto;
    }

    .marketingOptions {
        h6,
        p {
            padding: 5px 10px;
        }

        .checkboxGroup {
            display: flex;
            gap: 2rem;
        }
    }
}
