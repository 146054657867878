@import '@Hometree-styles/vars.scss';

.container {
    max-width: 1300px;
    margin: 0 auto;
    padding: 2rem;
}

.formGroup {
    > div {
        background-color: $secondary-grey-100;
        margin-bottom: 2rem;
        border-radius: 2rem;
        padding: 1rem;

        .header {
            margin: 0 2rem;
        }
    }
}

.selectedPlan {
    background-color: $primary-highlight-green;
    padding: 1rem 2rem;

    > div {
        max-width: 1300px;
        margin: 0 auto;
        display: block;

        p {
            margin: 0;
            font-weight: 600;

            span {
                font-weight: 200;
            }
        }
    }
}
