@import '@Hometree-styles/vars.scss';

.propertyInformationForm {
    padding: 20px 30px 35px;

    form {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem 3rem;

        .textInput {
            min-width: 300px;
            width: 47%;
            margin-bottom: 1rem;

            label {
                font-size: 1rem;
                font-weight: 500;
                margin-left: 24px;
            }
        }
    }

    .nextBtn {
        justify-self: right;
        width: 206px;
        justify-content: center;
        margin-top: 3rem;
    }

    .error {
        font-weight: 600;
        color: $error;
        margin: 1rem 0 1.5rem;
    }
}
