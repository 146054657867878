@import '@Hometree-styles/vars.scss';

.container {
    margin-left: 25px;
    margin-bottom: 15px;

    .titleWrapper {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 15px;
        gap: 20px;
        align-items: center;

        a {
            padding: 10px 15px;
            text-decoration: none;
        }
    }
    .title {
        font-weight: 600;
        color: $secondary-grey-900;
        margin: 1.5rem 0 1rem;
    }
    .linksWrapper {
        padding: 10px 0;
        .links {
            border: 1px solid black;
            border-radius: 20px;
            padding: 10px 15px;
            margin-right: 10px;
            color: $secondary-grey-900;
            align-self: center;
            &:hover {
                background-color: $primary-highlight-green;
            }
        }
    }

    .selectWrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }
    .label {
        display: block;
        margin: 10px 0;
        text-transform: uppercase;
        color: $secondary-grey-900;
        margin-right: 10px;
    }
    .labelError {
        color: $error;
    }
    .select {
        display: block;
        margin-top: 10px;
        border-radius: 10px;
        padding: 10px;
        font-size: 1rem;
    }
    .inputError {
        border-color: $error;
        color: $error;
    }
    .textArea {
        display: block;
        width: 100%;
        border-radius: 10px;
        height: 100px;
        margin: 10px 0;
    }
    .buttonWrapper {
        display: flex;
    }
    .backButton {
        margin-right: 15px;
    }
    .labelCancelGC {
        text-transform: none;
        margin: 20px 0;
    }
    .errorText {
        color: $error;
        text-transform: none;
    }
}
.cancelDropdown {
    height: 60px;
    margin-top: 10px;
}
