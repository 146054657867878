@import '@Hometree-styles/vars.scss';

.container {
    width: 100%;

    .iconsContainer {
        display: grid;
        gap: 25px 45px;
        margin-top: 20px;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

        .iconsList {
            display: flex;
            align-items: flex-start;
            gap: 20px;

            div {
                align-items: flex-start;
            }

            .appliancesDescription {
                .applianceType {
                    border-bottom: 1px solid $primary-mid-green-2;
                    font-weight: 600;
                    padding-bottom: 5px;
                    margin: 0 0 0.2rem;
                    display: flex;
                    justify-content: space-between;

                    .infoButton {
                        border: none;
                        background: none;
                        vertical-align: text-top;
                    }
                }

                .appliancePricing {
                    font-weight: 700;
                    color: $primary-dark-green;
                    min-width: 100px;
                    margin: 0;

                    .appliancePrice {
                        font-size: 0.8rem;
                        font-weight: 500;
                    }
                }

                p.appliancePricing:not(:first-of-type) {
                    color: $secondary-grey-opacity-1;
                    font-size: 0.9rem;
                }
            }
        }
    }

    .additionalIcons {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 25px;
        cursor: pointer;

        .rotated {
            transform: rotate(180deg);
        }
    }
}
