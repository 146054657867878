@import '@Hometree-styles/vars.scss';

.page {
    padding: 20px 40px;
    .container {
        margin: 16px 0;

        th:first-of-type,
        td:first-of-type {
            text-align: center;
        }
        .linkIcon {
            height: 24px;
        }
    }

    .titleSection {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h3 {
            padding: 5px;
            margin-bottom: 2rem;
        }
    }

    .sectionTitleContainer {
        display: flex;
        align-items: flex-end;
        margin-bottom: 1.5rem;
        gap: 16px;

        .sectionTitle {
            margin: 1.5rem 0 0;
        }
    }

    .statusContainer {
        display: flex;
        position: relative;
        justify-content: space-between;

        .historyIcon {
            cursor: pointer;

            &.open {
                path {
                    stroke: $secondary-grey-500;
                }
            }
        }
        .totalCostContainer {
            margin-left: 20px;

            .totalCostWarning {
                color: $error;
            }
        }
    }
}

.contractSection {
    background-color: $primary-highlight-green;
    height: 82px;
    display: flex;
}

.accordionFooter {
    background-color: $secondary-turquoise-100;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.paymentStatusContainer {
    padding: 15px 20px;
    background-color: $secondary-green-500;
    color: $primary-off-white;

    &.unknown {
        background-color: $secondary-orange-500;
    }

    &.errorStatus {
        background-color: $error;
    }

    p {
        margin: 0;
    }
}

#differentHeader {
    th,
    td {
        &:first-child {
            width: 100px;
            padding-left: 20px;
            min-width: 100px;
            text-align: center;
        }
    }
}

.notesContainer {
    padding: 20px 40px;
}

.notFound {
    color: $secondary-grey-500;
    font-weight: 600;
}

.addNewNote {
    display: flex;
    min-width: max-content;
    gap: 15px;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 20px;
    font-weight: 600;

    svg {
        background: $primary-yellow;
        padding: 8px;
        border-radius: 50px;
    }
}

.promocodeContainer {
    width: 100px;

    input {
        width: fit-content;
    }
}

.headerWithIcon {
    display: flex;
    align-items: center;

    .arrowBtn {
        padding: 0;
        background: none;
        color: #fff;
        transform: rotate(-90deg);

        &:hover {
            background: none;
        }
        path {
            stroke: $primary-yellow;
        }
    }
}

.readOnlyBanner {
    background-color: $secondary-mid-blue;
    padding: 15px 20px;
    color: $primary-off-white;

    svg {
        vertical-align: sub;
        margin-right: 5px;
    }
}

.changeEmailBanner {
    background-color: $secondary-grey-100;
    color: $white;
    padding: 15px 20px;
    svg {
        vertical-align: bottom;
        margin-right: 10px;
    }

    p {
        margin: 0;
        color: $secondary-grey-900;
    }

    span {
        font-weight: 600;
        color: $error;
    }

    > :last-child {
        margin-left: 27px;
    }
}
