@import '@Hometree-styles/vars.scss';

.container {
    margin: 16px 0;
    padding: 40px 40px 40px;

    .gridContainer {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;

        & > :nth-child(1),
        & > :nth-child(2),
        & > :nth-child(3) {
            grid-column: span 1;
        }

        & > :nth-child(4) {
            grid-column: span 3;
        }

        & > :nth-child(5),
        & > :nth-child(6),
        & > :nth-child(7),
        & > :nth-child(8) {
            grid-column: span 1;
        }

        & > :nth-child(9),
        & > :nth-child(10) {
            grid-column: span 1;
        }
    }

    .placeholderInput {
        input::placeholder {
            color: $primary-dark-green;
            font-weight: 600;
        }

        input:focus::placeholder {
            font-weight: normal;
        }
    }

    .section {
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 30px 10px;
        border-radius: 10px;
        border: 1px solid #172b02;
        padding: 30px;

        .icon {
            cursor: pointer;
        }

        label {
            font-size: 1rem;
            font-family: $font-body;
            font-weight: 400;
            display: flex;
            align-items: center;
            gap: 10px;
        }

        .dropdownContribution {
            .contributionTitle {
                margin-bottom: 15px;
            }
        }

        .packageTypeContainer {
            width: max-content;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0;

            .packageType {
                margin-top: 1rem;
                width: max-content;
                border-radius: 10px;
                border: 1px solid $secondary-green-900;
                font-size: 1rem;
                padding: 8px 8px 8px 20px;
                color: $primary-dark-green;
                font-weight: 600;
            }
        }

        .dropdownButtons {
            display: flex;
            gap: 20px;
            padding: 0 15px;

            button {
                width: 67px;
                height: 28px;
                padding: 10px;
                font-size: 0.9rem;
                justify-content: center;
            }
        }

        .accommodationContainer {
            display: flex;
            width: 100%;
            gap: 10px;
            align-items: flex-end;

            p {
                margin: 0;
            }
        }
    }
    .submitButtons {
        display: flex;
        gap: 20px;
        width: 100%;
    }
}
