.contractsContainer {
    margin: 20px;
    overflow-x: scroll;
    width: 80vw;

    table {
        width: 100%;
        table-layout: auto;
    }

    #differentHeader {
        th,
        td {
            width: max-content;
            text-wrap: nowrap;

            &:first-child {
                text-align: center;
            }
        }
    }
}
