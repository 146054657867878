.paymentInformationForm {
    padding: 20px 30px 35px;

    .formContainer {
        display: flex;
        justify-content: space-between;
    }

    .paymentMethodList {
        > label {
            font-size: 1rem;
            font-weight: 700;
        }

        ul {
            list-style: none;
            padding: 0;
            margin-top: 1rem;

            .eachPaymentMethod {
                display: flex;
                gap: 2rem;
                width: 100%;
                margin-bottom: 1rem;

                .directDebitLogo svg {
                    width: 95px;
                }

                > div {
                    display: flex;
                    gap: 1rem;
                }
            }
        }
    }
}
