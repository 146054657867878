@import '@Hometree-styles/vars.scss';

.radioContainer {
    label {
        display: flex;
        align-items: flex-start;
        gap: 0.75rem;
    }

    &.disabled {
        opacity: 0.2;
        cursor: not-allowed;

        input + span {
            cursor: not-allowed;
        }
    }

    input {
        opacity: 0;
        position: absolute;
        z-index: -1;

        &:checked {
            + span::after {
                background-color: $primary-mid-green-2;
            }
        }

        + span {
            display: block;
            height: 18px;
            width: 18px;
            border: 2px solid $primary-mid-green-2;
            border-radius: 15px;
            cursor: pointer;
            overflow: hidden;
            position: relative;

            &::after {
                content: ' ';
                width: 13px;
                height: 13px;
                border-radius: 15px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}
