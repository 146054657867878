@import '@Hometree-styles/vars.scss';

.container {
    color: $secondary-grey-500;
    margin-left: 25px;
    margin-bottom: 15px;
    .title {
        font-weight: 600;
        color: $secondary-grey-900;
        margin-top: 0;
        font-size: 1.3rem;
    }
    .form {
        display: flex;
        margin-bottom: 20px;
    }
    .label {
        margin-right: 10px;
        text-transform: uppercase;
        font-size: 0.9rem;
    }
    .input {
        display: block;
        margin-top: 10px;
        padding: 8px;
    }
}
