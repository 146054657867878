@import '@Hometree-styles/vars.scss';
@import '@Hometree-styles/mixins.scss';

.inactive {
    path {
        color: $error;
        stroke: $error;
    }
}

.icon {
    padding-left: 10px;
}

.invisibleBtn {
    background: none;
    @include a;

    &:hover {
        background: none;
    }
}
