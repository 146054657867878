@import '@Hometree-styles/vars.scss';

.promocodeContainer {
    width: fit-content;
    margin: 1.5rem 0;
    display: flex;
    gap: 15px;
    align-items: center;

    input {
        width: fit-content;
    }

    label {
        font-size: 1rem;
        font-family: $font-body;
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .icon {
        cursor: pointer;
        width: 24px;
        height: 24px;
    }
}

.icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
}

.cancel {
    cursor: pointer;
    path {
        stroke: $error;
    }
}

.promoTitle {
    margin: 1.5rem 0 0;
}
