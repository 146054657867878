@import '@Hometree-styles/vars.scss';

.accordionContainer {
    width: 100%;

    .accordionTitle {
        background-color: $primary-highlight-green;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: none;
        width: 100%;
        text-align: left;
        font-family: $font-body;
        font-size: 1.5rem;
        font-weight: 700;
        padding: 24px;
        border-bottom: 1px solid $primary-dark-green;

        &:hover {
            background-color: $primary-yellow;
        }

        &:active {
            background-color: $primary-highlight-green;
        }
    }

    .accordionContent {
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.2s ease-out;
    }

    .open {
        overflow: auto;
        max-height: 800px;
    }

    .rotated {
        transform: rotate(180deg);
    }
}
