@import '@Hometree-styles/vars.scss';

.postcodeFinder {
    margin-bottom: 1rem;
    position: relative;
    display: flex;
    gap: 1rem;
    width: 100%;

    .inputGroupButton {
        display: flex;
        border: 1px solid $secondary-green-900;
        border-radius: 10px;
        overflow: hidden;
        align-items: center;

        input {
            border: 0;
            border-radius: 0;
        }

        button {
            border-radius: 0;
            height: 38px;
        }
    }

    .dropdown {
        width: 600px;
        max-width: none;
        align-self: auto;

        > div {
            width: 100%;
            max-width: none;

            > button {
                width: 100% !important;
                display: block;

                > div {
                    width: 100% !important;
                }
            }
        }
    }
}
