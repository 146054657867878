@import '@Hometree-styles/vars.scss';

.collapsibleContainer {
    .collapsibleHeader {
        cursor: pointer;
        width: 100%;
        display: flex;
        flex-direction: row;
        padding: 0.25rem 0;
        justify-content: space-between;
        background: none;
        border: none;
        align-items: center;

        .plusIcon {
            height: 15px;
            opacity: 1;
            stroke-width: 15px;
            stroke: rgb(17, 17, 17);
            transform: rotate(45deg);
            width: 15px;
        }

        h2,
        h4 {
            margin: 0;
        }
    }
    .content {
        margin-bottom: 1rem;
    }

    &.collapsed {
        .content {
            display: none;
        }
    }

    &.faq {
        border-radius: 1rem 1rem 1rem 0rem;
        border: solid 1px $primary-dark-green;
        padding: 0 2rem;
        width: 50%;
    }

    &.inclusions {
        .collapsibleHeader {
            padding: 0.75rem 0;
        }
    }

    .plusIconThick {
        height: 15px;
        opacity: 1;
        right: 0;
        stroke-width: unset;
        stroke: rgb(17, 17, 17);
        transform: rotate(0deg);
        width: 15px;
    }
}

.disabled {
    .collapsibleHeader {
        cursor: not-allowed;
    }
    h2 {
        color: $secondary-grey-500;
    }
    .plusIcon {
        display: none;
    }
}
