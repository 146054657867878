@import '@Hometree-styles/vars.scss';

.container {
    margin: 16px 0;

    .sectionTitleContainer {
        display: flex;
        align-items: center;
        gap: 20px;
        margin: 1.5rem 0 1rem;
    }

    .contentContainer {
        display: flex;
        align-items: baseline;
        gap: 10px;
        color: $primary-dark-green;
        margin-bottom: 0.5rem;
        .contentValue {
            padding-right: 1rem;
            border-right: 1px solid $primary-dark-green;
        }

        .contentType {
            padding-left: 0.25rem;
            line-height: 95%;
        }
    }

    .placeholderInput {
        input::placeholder {
            color: $primary-dark-green;
        }

        input:focus::placeholder {
            font-weight: normal;
        }
    }
    .section {
        border-radius: 10px;
        border: 1px solid #172b02;
        padding: 30px;
        .contractElements {
            display: flex;
            flex-wrap: wrap;
            gap: 35px;
            position: relative;

            .icon {
                cursor: pointer;

                &_link {
                    cursor: pointer;
                    height: 24px;
                    width: 24px;
                }
            }

            label {
                font-size: 1rem;
                font-family: $font-body;
                font-weight: 400;
                display: flex;
                align-items: center;
                gap: 10px;
                height: 18px;
            }

            label[for='packageType'] {
                height: fit-content;
            }

            .dropdownContribution {
                .contributionTitle {
                    margin-bottom: 15px;
                }
            }

            .noBorder {
                outline: none;

                input[id='gasFire'] {
                    border: none;
                    padding: 8px 4px;
                }
            }

            .packageTypeContainer {
                width: max-content;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 0;

                .packageType {
                    margin-top: 1rem;
                    width: max-content;
                    border-radius: 25px;
                    border: 1px solid $secondary-green-900;
                    font-size: 1rem;
                    padding: 16px 25px 8px;
                    color: $primary-dark-green;
                }
            }

            .dropdownButtons {
                display: flex;
                gap: 20px;
                padding: 0 15px;

                button {
                    width: 67px;
                    height: 28px;
                    padding: 10px;
                    font-size: 0.9rem;
                    justify-content: center;
                }
            }

            .accommodationContainer {
                display: flex;
                width: 100%;
                gap: 10px;
                align-items: flex-end;

                p {
                    margin: 0;
                }
            }
        }

        .fixedPriceAddon {
            > label {
                display: flex;
                flex-direction: row;
                padding: 1.5rem 0 2rem 0;
                align-items: center;
            }
        }

        .submitButtons {
            display: flex;
            gap: 20px;
            width: 100%;
        }
    }
}

.historyAlignment {
    margin-top: 30px;
    left: 30px;
}
