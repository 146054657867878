@import '@Hometree-styles/vars.scss';

.contractTableContainer {
    margin: 16px 0;
    #firstTdStyle {
        p {
            color: white;
        }
        th:first-of-type,
        td:first-of-type {
            padding-left: 65px;
        }
    }

    .icon {
        width: 20px;
        height: 20px;

        path {
            stroke: $primary-dark-green;
            fill: $primary-dark-green;
        }
    }

    .openRentIcon {
        height: 20px;
    }
}
