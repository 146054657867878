@import '@Hometree-styles/vars.scss';

.page {
    padding: 20px 40px;
    .container {
        margin: 16px 0;

        th:first-of-type,
        td:first-of-type {
            text-align: center;
        }
    }
}
