@import '@Hometree-styles/vars.scss';

.container {
    padding: 20px 40px 40px;
    margin-bottom: 4rem;

    .paymentMethodContainer {
        border: 1px solid black;
        padding: 15px 30px;
        border-radius: 10px;

        h3 {
            margin: 1rem 0;
        }
    }

    .paymentMethods {
        display: flex;
        flex-direction: row;
        gap: 45px;

        .fields {
            position: relative;
        }

        h3 {
            margin: 1rem 0;
        }

        .historyIcon {
            cursor: pointer;

            &.open {
                path {
                    stroke: $secondary-grey-500;
                }
            }
        }
    }

    .icon {
        display: block;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }

    label {
        display: flex;
        align-items: flex-end;
        gap: 10px;
        font-size: 1rem !important;
        font-weight: normal !important;
    }

    input {
        max-width: 300px;
        width: max-content;
    }

    .paymentHeader {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 1.5rem;
    }

    .paymentButtons {
        display: flex;
        gap: 30px;
        padding: 30px 0 10px;
    }
    .links {
        border: 1px solid black;
        border-radius: 20px;
        padding: 10px 15px;
        margin-right: 10px;
        color: $secondary-grey-900;
        align-self: center;
        text-decoration: none;

        &:hover {
            background-color: $primary-highlight-green;
        }
    }

    .gocardlessTable {
        padding: 20px 0;

        th:first-of-type,
        td:first-of-type {
            text-align: center;
        }
    }
}

.historyAlignment {
    left: 0;
}
