@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';
@import '@fontsource/roboto/700.css';

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
    font-family: 'Coaster';
    src: local('Coaster'), url(./assets/fonts/Coaster.otf) format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Hometree';
    src: local('Hometree'), url(./assets/fonts/hometree/Hometree-Regular.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Hometree';
    src: local('Hometree'), url(./assets/fonts/hometree/Hometree-Medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Hometree';
    src: local('Hometree'), url(./assets/fonts/hometree/Hometree-SemiBold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Hometree';
    src: local('Hometree'), url(./assets/fonts/hometree/Hometree-Bold.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

* {
    font-family: 'Hometree', 'Arial Black', 'sans serif';
}
