@import '@Hometree-styles/vars.scss';

.boilerInformationForm {
    padding: 20px 30px 35px;

    .nextSteps {
        padding: 5px 20px 10px 20px;
        border: 2px solid $secondary-green-500;
        border-radius: 25px;
        margin-bottom: 3rem;
    }

    .boilerInfoForm {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        flex-direction: column;
        border: 1px solid $primary-dark-green;
        border-radius: 25px;
        margin-bottom: 3rem;
        padding: 2rem;

        label {
            font-size: 1rem;
            margin-top: 1rem;
        }

        .textInput {
            min-width: 300px;
        }

        .submitBtn {
            width: 240px;
            display: block;
            margin: auto;
        }
    }
}
