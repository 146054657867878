@import '@Hometree-styles/vars.scss';

.infoBoxWrapper {
    height: 50px;
    gap: 1rem;

    .cancellationDetails {
        border-radius: 0px 0px 10px 10px;
        border: 1px solid $primary-dark-green;
        background: $white;
        box-shadow: 0px 4px 4px 0px $shadow;
        position: relative;
        top: -10px;
        padding: 0 14px 0 14px;

        p {
            padding: 10px 0 9px 0;
        }

        .divider {
            border-top: 3px solid;
            color: $secondary-grey-opacity-2;

            h5 {
                margin-top: 1rem;
            }

            p {
                color: $primary-dark-green;
                padding-top: 10px;
            }
        }
    }

    .contentValue {
        padding-right: 0.25rem;
        font-weight: 600;
        border-right: 1px solid $primary-dark-green;
    }

    .historyIcon {
        cursor: pointer;

        path {
            stroke: $secondary-green-500;
        }

        &.open {
            path {
                stroke: $secondary-grey-500;
            }
        }

        &.cancelledIcon {
            path {
                stroke: $secondary-error-100;
            }
            &.open {
                path {
                    stroke: $secondary-error-900;
                }
            }
        }
    }
}

.cancellationDropdownButton {
    border: none;
    background: none;

    .icon {
        svg {
            rect {
                fill: $white;
            }
        }
    }
}

.historyAlignment {
    left: 0;
    margin-top: -15px;
}
