@import '@Hometree-styles/vars.scss';

.notesContainer {
    padding: 20px 40px;
    gap: 20px;
    display: flex;
    flex-direction: column;

    p {
        margin: 0;
    }
}

.notesHeader {
    display: flex;
    font-weight: 600;
    justify-content: space-between;
    align-items: center;
}

.notFound {
    color: $secondary-grey-opacity-1;
    font-weight: 600;
}

.addNewNote {
    display: flex;
    min-width: max-content;
    gap: 15px;
    justify-content: flex-end;
    align-items: center;
    font-weight: 600;
    flex: 1;

    svg {
        background: $primary-yellow;
        padding: 8px;
        border-radius: 50px;
    }
}

.submitButtons {
    display: flex;
    gap: 20px;
    width: 100%;
}
.noteInputContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-weight: 600;

    .label {
        display: flex;
        align-items: flex-end;
    }

    .icon {
        margin-left: 10px;
        cursor: pointer;
    }

    .noteInput {
        border-radius: 10px;
        padding: 15px;
    }
}

.plusBtn {
    border: none;
    background: none;
}
