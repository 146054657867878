@import '@Hometree-styles/vars.scss';

.noteBlockWrapper {
    color: #656d77;
    display: flex;
    flex-direction: row;
    margin-left: 80px;
}

.noteBlockWrapper:nth-of-type(1) .iconWrapper path,
.noteBlockWrapper:nth-of-type(1) .iconWrapper circle {
    stroke: $secondary-green-500;
}

.noteBlockWrapper .iconWrapper {
    padding-top: 15px;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.noteBlockWrapper .captionNotes {
    padding-left: 20px;
}

.noteBlockWrapper .captionNotes .description {
    overflow-wrap: anywhere;
}

.noteBlockWrapper .captionNotes time {
    font-size: 0.8em;
}

.noteBlockWrapper .captionNotes title {
    display: block;
    font-size: 1.17em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}

.noteBlockWrapper .captionNotes title .authorby {
    font-weight: normal;
}

.noteDescriptionLine {
    display: block;
}
