@import '@Hometree-styles/vars.scss';

.bgWhite {
    background: $white;
}

.bgGreen {
    background: $secondary-green-100;
}

.bgRed {
    background: $error;
}

.infoBoxContainer {
    border-radius: 10px;
    border: 1px solid $secondary-grey-opacity-1;
    padding: 9px 15px 7px;
    display: flex;
    gap: 10px;
    z-index: 1;
    position: relative;
    align-items: center;

    .contentContainer {
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        flex-wrap: nowrap;
        gap: 10px;

        .infoBoxHeading {
            font-weight: 600;
        }
    }

    .oneLineContainer {
        flex-direction: row;
        gap: 5px;
        align-items: center;

        .infoBoxHeading,
        .childrenStyle {
            align-items: center;
            p {
                margin: 0;
            }
        }
        &.cancellationContainer {
            color: $primary-off-white;

            .childrenStyle {
                display: flex;
                gap: 15px;
            }
        }
    }

    .icon {
        display: block;
        width: 24px;
        height: 24px;
        align-self: baseline;
    }
}
