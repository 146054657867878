.warningContent {
    padding: 10px;
}

.iconContainer {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    margin-top: -20px;
    font-size: 2.5rem;
    font-weight: 700;
    gap: 10px;
}

.buttonsContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 20px;
    gap: 20px;
}
