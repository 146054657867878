@import '@Hometree-styles/vars.scss';

.contentContainer {
    display: flex;
    flex-direction: row;

    color: $primary-dark-green;

    .contentValue {
        padding-right: 0.25rem;
        border-right: 1px solid $primary-dark-green;
    }

    .contentType {
        padding-left: 0.25rem;
        opacity: 60%;
    }
}
