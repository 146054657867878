@import '@Hometree-styles/vars.scss';

.claimDetailsContainer {
    background-color: $secondary-grey-100;
    display: grid;
    grid-row-gap: 1.5rem;
    grid-column-gap: 1rem;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(1, 7em);
    padding: 2rem 3rem;
    height: 15em;

    .costSpan {
        min-width: 250px;

        &.claim {
            cursor: pointer;
            max-height: 20px;
            display: inline-block;
        }

        svg {
            vertical-align: middle;
            path {
                stroke: $secondary-green-500;
            }
        }
    }

    .costLimit {
        svg {
            padding-right: 16px;
        }
    }

    .errorMsg {
        color: $error;
    }
}

.bodyStyle {
    display: flex;
    align-items: center;
}

.skeletonContainer {
    padding: 2rem 3rem;
}

.cellContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    h6 {
        margin: 0;
        line-height: 1.5;
    }

    p {
        margin: 0;
    }
}

.costBreakDownContainer {
    width: 300px;
    max-width: 300px;
    border-radius: 20px;
    box-shadow: 0px 7px 7px 1px #888888;
    padding: 15px;
    background-color: white;
    z-index: 1;

    .costWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1em;
        margin-top: 0;
    }

    .closeButton {
        background-color: inherit;
        border: none;
        width: 30px;
        cursor: pointer;
    }

    .costTitle {
        color: $secondary-green-500;
        font-style: normal;
        font-weight: 600;
    }

    .costSubtitle {
        font-weight: 500;
        padding-right: 25px;
    }

    .costValue {
        font-weight: 600;
    }
}
