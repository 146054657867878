@import '@Hometree-styles/vars.scss';

.loadingOverlay {
    background-color: $secondary-grey-opacity-1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: not-allowed;
    z-index: 100;

    .loader {
        width: 4rem;
        height: 4rem;
        border: 9px solid $primary-dark-green;
        border-bottom-color: #fff;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}
