@import '@Hometree-styles/vars.scss';

.main {
    position: relative;
    width: 210px;
    max-width: 210px;
    align-self: center;

    .dropdownContainer {
        font-size: 1.3rem;
        border: 1px solid $primary-dark-green;
        border-radius: 25px;
        position: absolute;
        background-color: #fff;
        z-index: 1;
        overflow: hidden;
    }

    .button {
        border: 0;
        background: inherit;
        margin: 0;
        padding: 6px;
        border-radius: 25px;

        .dropdownFirstItem {
            display: flex;
            justify-content: space-between;
            font-size: 1rem;
            font-style: normal;
            line-height: 14px;
            letter-spacing: -0.64px;
            width: 200px;
        }

        .selectedOption {
            padding: 10px 20px;
            margin: 0;
            text-align: left;
        }

        .icon {
            align-self: center;
            padding-right: 20px;
        }

        &:disabled {
            cursor: not-allowed;
        }
    }

    .dropdownList {
        z-index: 100;
        margin: 0;
        font-size: 1.3rem;
        padding: 0;
        position: relative;
        max-height: 300px;
        overflow-y: auto;

        &:first-child {
            padding-top: 0.8em;
        }
        .listItem {
            list-style: none;
        }
        .listItemButton {
            display: flex;
            justify-content: space-between;
            padding: 0px 15px 0px 20px;
            width: 100%;
            background-color: inherit;
            border: 0;
            &:hover {
                background-color: $primary-highlight-green;
            }
        }

        .listItemIcon {
            padding-top: 3px;
        }

        .listText {
            margin: 0;
            padding: 0px;
            color: $primary-dark-green;
            font-size: 1rem;
            font-style: normal;
            line-height: 19.2px;
            letter-spacing: -0.64px;
            align-self: center;
            text-align: left;
        }
        .selected {
            background-color: $primary-yellow;
            font-weight: 600;
        }
    }
}
