@import '@Hometree-styles/vars.scss';

.container {
    width: 100%;

    .boilerForm {
        max-width: 1300px;
        margin: 0 auto;
        padding: 2rem;

        h4 {
            padding-left: 30px;
            margin-bottom: 2rem;
        }

        a {
            text-decoration: none;
        }

        .contractLinkIcon {
            width: 24px;
            height: 24px;
            display: inline-block;
        }
    }
}

.errorMessage {
    color: $error;
}
