@import '@Hometree-styles/vars.scss';

.history {
    margin-top: 6px;
    display: none;
    position: absolute;
    right: 0;
    border: 1px solid $primary-dark-green;
    border-radius: 10px;
    background-color: #fff;
    z-index: 1;

    &.leftAligned {
        left: 0;
    }

    .noData {
        padding: 10px 20px;
    }

    &.open {
        display: block;
        width: max-content;
        top: 75px;
    }

    &_list {
        list-style: none;
        padding: 0;
        margin: 0;
        background-color: #fff;
        border-radius: 10px;
    }

    &_item {
        margin: 0;
        border-bottom: 1px solid $secondary-grey-900;
        display: flex;
        gap: 15px;

        &:last-of-type {
            border: none;
        }
    }

    .current {
        background-color: $primary-highlight-green;
        color: $primary-dark-green;
        border-radius: 10px 10px 0 0;
    }

    &_header {
        display: flex;
        justify-content: space-between;
        padding: 12px 20px;
        flex-direction: column;
        width: 140px;
    }
    &_author {
        border-left: 1px solid black;
        padding-left: 15px;
        margin: 0;
        padding: 12px 20px;
        display: flex;
        align-items: flex-end;
    }

    &_author,
    &_time {
        margin: 0;
    }

    &_time {
        font-weight: 600;
    }

    &_value {
        margin: 0.5rem 0;
    }
}
